@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  padding: 0;
  margin: 0;
  background-color: black;
}

.viewer {
  display: grid;
  place-items: center;
  height: 100vh;
  width: 100vw;
  cursor: grabbing;
  /* background: rgb(77,77,77);
  background: radial-gradient(circle, rgba(77,77,77,1) 0%, rgba(0,0,0,1) 100%); */
  overflow: hidden;
  background-color:#000000 ;
}

.fullscreen {
  height: 100vh;
  overflow: hidden;
}

.changer {
  position: absolute;
  top: 10px;
  left: 10px;
  cursor: pointer;
}